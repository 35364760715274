<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <Loading
        v-if="modificando"
        :full-page="true"
        :color="$vuetify.theme.themes.light.primary"
      />

      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('lbl.cargaLote')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-contrate-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              v-if="lote !== null"
              cols="12"
              md="2"
            >
              <!--:disabled="!lote.listo"-->
              <!-- || name_proveedor.toLocaleLowerCase().includes('blue-diamond') -->
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :disabled="!lote.listo || name_proveedor.toLocaleLowerCase().includes('blue-diamond')"
                :loading="processUpload"
                @click="acceptUpload()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="lote !== null"
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="canceleUpload()"
              >
                <span>{{ $t('btn.canceleUpload') }}</span>
                <v-icon>{{ icons.mdiCancel }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row v-if="lote === null && !modificando">
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="model.proveedor_id"
                  :items="itemsProveedor"
                  :search-input.sync="searchProveedor"
                  hide-details
                  hide-selected
                  :label="$t('lbl.proveedor')"
                  outlined
                  dense
                  item-text="name_comercial"
                  item-value="id"
                  clearable
                  @change="changeProveedor"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.proveedor') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name_comercial"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.identificador"
                  :label="$t('lbl.identificador')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-file-input
                  v-model="file"
                  accept=".ods, .csv"
                  exc
                  show-size
                  outlined
                  dense
                  :label="$t('lbl.file')"
                  hide-details
                  :disabled="
                    !model.proveedor_id ||
                      (!name_proveedor.toLocaleLowerCase().includes('meliá') &&
                        !name_proveedor.toLocaleLowerCase().includes('melia') &&
                        !name_proveedor.toLocaleLowerCase().includes('iberostar') &&
                        !name_proveedor.toLocaleLowerCase().includes('blue-diamond'))
                  "
                  @change="setFile"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <fragment v-if="!model.proveedor_id">
                  <v-alert
                    dense
                    outlined
                    type="error"
                  >
                    {{ $t('msg.loteContrateHotel') }}
                  </v-alert>
                </fragment>
                <fragment v-else>
                  <fragment>
                    <h3 class="mb-3">
                      {{ $t('lbl.orderFileColumn') }}
                    </h3>
                    <v-img
                      v-if="
                        name_proveedor.toLocaleLowerCase().includes('meliá') ||
                          name_proveedor.toLocaleLowerCase().includes('melia')
                      "
                      lazy-src="@/assets/img/placeholder_lote_hotel_contrate_melia.png"
                      src="@/assets/img/placeholder_lote_hotel_contrate_melia.png"
                    >
                    </v-img>
                    <v-img
                      v-else-if="name_proveedor.toLocaleLowerCase().includes('iberostar')"
                      lazy-src="@/assets/img/placeholder_lote_hotel_contrate_iberostar.jpg"
                      src="@/assets/img/placeholder_lote_hotel_contrate_iberostar.jpg"
                      contain
                    >
                    </v-img>
                    <v-img
                      v-else-if="name_proveedor.toLocaleLowerCase().includes('blue-diamond')"
                      lazy-src="@/assets/img/placeholder_lote_hotel_contrate_blue_diamond.jpg"
                      src="@/assets/img/placeholder_lote_hotel_contrate_blue_diamond.jpg"
                      contain
                    >
                    </v-img>
                    <h3
                      v-else
                      class="mb-3"
                    >
                      {{ $t('lbl.notModel') }}
                    </h3>
                  </fragment>
                </fragment>
              </v-col>
            </v-row>

            <v-row v-if="!modificando">
              <fragment v-if="lote !== null">
                <v-col
                  cols="10"
                  offset-md="1"
                >
                  <v-expansion-panels>
                    <v-expansion-panel v-if="hotelsChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('lbl.hotels') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsNameHotel') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in hotelsChange"
                                :key="Math.random() * (index + 1)"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalHotel(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="rommsChange.length > 0">
                      <v-expansion-panel-header :disabled="hotelsChange.length > 0">
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.habitaciones') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsHabitaciones') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in rommsChange"
                                :key="Math.random() * (index + 1)"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalRoom(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="planesChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.regimenAlimenticio') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsPlanAlim') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in planesChange"
                                :key="Math.random() * (index + 1)"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalPlanes(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="suplementosChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.suplementosReduc') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsSuplementReduc') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in suplementosChange"
                                :key="Math.random() * (index + 1)"
                              >
                                <td width="80%">
                                  {{ item.name }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalSuplementos(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="reduccionesChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="2">
                              <h4 class="mt-2">
                                {{ $t('menu.reducciones') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsReducc') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in reduccionesChange"
                                :key="Math.random() * (index + 1)"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalReducciones(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <fragment v-if="lote.listo">
                  <v-col cols="12">
                    <fragment
                      v-for="(item, index) in lote.lote"
                      :key="Math.random() * (index + 1)"
                    >
                      <!--PARA LOS MELIA-->
                      <v-simple-table
                        v-if="
                          name_proveedor.toLocaleLowerCase().includes('meliá') ||
                            name_proveedor.toLocaleLowerCase().includes('melia')
                        "
                      >
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <th
                                rowspan="2"
                                width="20%"
                              >
                                {{ listHotel.filter(e => e.id === item.hotel.id)[0].name }}
                                <v-btn
                                  icon
                                  @click="showItem(index)"
                                >
                                  <v-icon>
                                    {{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                                  </v-icon>
                                </v-btn>
                              </th>
                              <th
                                v-if="showRow && index === indexRow"
                                rowspan="2"
                              >
                                {{ $t('lbl.temporadas') }}
                              </th>
                              <th v-if="showRow && index === indexRow">
                                {{ $t('lbl.from') }}
                              </th>
                              <fragment v-if="showRow && index === indexRow">
                                <th
                                  v-for="(from, indF) in item.seasons.from"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  {{ from }}
                                </th>
                              </fragment>
                            </tr>
                            <tr v-if="showRow && index === indexRow">
                              <th>
                                {{ $t('lbl.to') }}
                              </th>
                              <th
                                v-for="(to, indF) in item.seasons.to"
                                :key="Math.random() * (indF + 1)"
                              >
                                {{ to }}
                              </th>
                            </tr>

                            <fragment v-if="showRow && index === indexRow">
                              <!--CUPOS-->
                              <tr>
                                <td>
                                  <b>CUPOS</b>
                                </td>
                                <td><b>HABITACIONES</b></td>
                                <td :colspan="item.seasons.from.length + 1"></td>
                              </tr>
                              <tr
                                v-for="(cupo, indU) in item.cupos"
                                :key="Math.random() * (indU + 1)"
                              >
                                <td></td>
                                <td>
                                  {{ allRooms.filter(e => e.id === cupo.room.id)[0].name }}
                                </td>
                                <td></td>
                                <td
                                  v-for="(roomC, indR) in cupo.cupos"
                                  :key="Math.random() * (indR + 1)"
                                  class="text-center"
                                >
                                  {{ roomC }}
                                </td>
                              </tr>

                              <!--RELEASE-->
                              <tr>
                                <td>
                                  <b>RELEASE</b>
                                </td>
                                <td colspan="2"></td>
                                <td
                                  v-for="(release, indRe) in item.release"
                                  :key="Math.random() * (indRe + 1)"
                                  class="text-center"
                                >
                                  {{ release }}
                                </td>
                              </tr>

                              <!--ESTANCIAS-->
                              <tr>
                                <td>
                                  <b>ESTANCIAS</b>
                                </td>
                                <td><b>HABITACIONES</b></td>
                                <td><b>PLAN</b></td>
                                <td :colspan="item.seasons.from.length"></td>
                              </tr>
                              <tr
                                v-for="(estancia, indE) in item.estancias"
                                :key="Math.random() * (indE + 1)"
                              >
                                <td>{{ estancia.servicio }}</td>
                                <td>
                                  {{ allRooms.filter(e => e.id === estancia.room.id)[0].name }}
                                </td>
                                <td>
                                  {{ allPlanes.filter(e => e.id === estancia.plan.id)[0].siglas }}
                                </td>
                                <td
                                  v-for="(price, indP) in estancia.prices"
                                  :key="Math.random() * (indP + 1)"
                                  class="text-center"
                                >
                                  {{ price }}
                                </td>
                              </tr>

                              <!--SUPLEMENTOS DE ALOJAMIENTO-->
                              <tr>
                                <td>
                                  <b>SUPLEMENTOS DE ALOJAMIENTO</b>
                                </td>
                                <td><b>HABITACIONES</b></td>
                                <td :colspan="item.seasons.from.length + 1"></td>
                              </tr>
                              <tr
                                v-for="(suplemento, indS) in item.suplementos"
                                :key="Math.random() * (indS + 1)"
                              >
                                <td>
                                  {{ allSuplementos.filter(e => e.id === suplemento.suplemento.id)[0].name }}
                                </td>
                                <td>
                                  {{ allRooms.filter(e => e.id === suplemento.room.id)[0].name }}
                                </td>
                                <td></td>
                                <td
                                  v-for="(price, indP) in suplemento.prices"
                                  :key="Math.random() * (indP + 1)"
                                  class="text-center"
                                >
                                  {{ price }}
                                </td>
                              </tr>

                              <!--REDUCCIONES-->
                              <tr>
                                <td>
                                  <b>REDUCCIONES</b>
                                </td>
                                <td :colspan="item.seasons.from.length + 3"></td>
                              </tr>
                              <tr
                                v-for="(reduccion, indS) in item.reducciones"
                                :key="Math.random() * (indS + 1)"
                              >
                                <td colspan="3">
                                  {{ allReducciones.filter(e => e.id === reduccion.reduccion.id)[0].name }}
                                </td>
                                <td
                                  v-for="(price, indP) in reduccion.prices"
                                  :key="Math.random() * (indP + 1)"
                                  class="text-center"
                                >
                                  {{ price }}
                                </td>
                              </tr>

                              <!--SUPLEMENTOS DE PLAN ALIMENTICIO-->
                              <tr>
                                <td colspan="3">
                                  <b>SUPLEMENTOS DE PLAN ALIMENTICIO</b>
                                </td>
                                <td :colspan="item.seasons.from.length"></td>
                              </tr>
                              <tr
                                v-for="(suplemento, indS) in item.supl_alim"
                                :key="Math.random() * (indS + 1)"
                              >
                                <td colspan="3">
                                  {{ suplemento.suplemento_alimenticio }}
                                </td>
                                <td
                                  v-for="(price, indP) in suplemento.prices"
                                  :key="Math.random() * (indP + 1)"
                                  class="text-center"
                                >
                                  {{ price }}
                                </td>
                              </tr>

                              <!--USO-->
                              <tr>
                                <td>
                                  <b>USO</b>
                                </td>
                                <td><b>HABITACIONES</b></td>
                                <td :colspan="item.seasons.from.length + 1"></td>
                              </tr>
                              <tr
                                v-for="(uso, indE) in item.usos"
                                :key="Math.random() * (indE + 1)"
                              >
                                <td></td>
                                <td>
                                  {{ allRooms.filter(e => e.id === uso.room.id)[0].name }}
                                </td>
                                <td></td>
                                <td class="text-left">
                                  {{ uso.ocup_max }}
                                </td>
                                <td class="text-left">
                                  {{ uso.max_adult }}
                                </td>
                                <td class="text-left">
                                  {{ uso.ocup_min }}
                                </td>
                                <fragment v-if="item.seasons.from.length >= 4">
                                  <td :colspan="item.seasons.from.length - 3"></td>
                                </fragment>
                              </tr>
                            </fragment>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <!--PARA LOS IBEROSTAR-->
                      <v-simple-table v-if="name_proveedor.toLocaleLowerCase().includes('iberostar')">
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <th v-if="showRow && index === indexRow"></th>
                              <th v-if="showRow && index === indexRow">
                                <b>Hotel</b>
                              </th>
                              <th
                                :colspan="showRow && index === indexRow ? item.seasons.from.length : 1"
                                class="text-left"
                              >
                                {{ listHotel.filter(e => e.id === item.hotel.id)[0].name }}
                                <v-btn
                                  icon
                                  @click="showItem(index)"
                                >
                                  <v-icon>
                                    {{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                                  </v-icon>
                                </v-btn>
                              </th>
                            </tr>
                            <fragment v-if="showRow && index === indexRow">
                              <!--Destino-->
                              <tr>
                                <td></td>
                                <td>
                                  <b>Destino</b>
                                </td>
                                <td
                                  :colspan="item.seasons.from.length"
                                  class="text-left"
                                >
                                  <span v-if="item.destino.length > 0">
                                    {{ item.destino[0].name }}
                                  </span>
                                </td>
                              </tr>
                              <!--Plan-->
                              <tr>
                                <td></td>
                                <td>
                                  <b>Plan</b>
                                </td>
                                <td
                                  :colspan="item.seasons.from.length"
                                  class="text-left"
                                >
                                  <span v-if="item.plan.length > 0">
                                    {{ item.plan[0].name }}
                                  </span>
                                </td>
                              </tr>
                              <!--Temporadas-->
                              <tr>
                                <td></td>
                                <td>
                                  <b>Temporadas</b>
                                </td>
                                <td
                                  v-for="(name, indF) in item.seasons.name"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  <b>{{ name }}</b>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Desde</td>
                                <td
                                  v-for="(from, indF) in item.seasons.from"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  {{ from }}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Hasta</td>
                                <td
                                  v-for="(to, indF) in item.seasons.to"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  {{ to }}
                                </td>
                              </tr>
                              <!--Release y Estancia Mínima-->
                              <tr>
                                <td></td>
                                <td>
                                  <b>Release y Estancia Mínima</b>
                                </td>
                                <td
                                  v-for="(name, indF) in item.seasons.name"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  <b>{{ name }}</b>
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Release</td>
                                <td
                                  v-for="(relea, indF) in item.release.release"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  {{ relea }}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Estancia Mínima</td>
                                <td
                                  v-for="(estanc, indF) in item.release.estancia_min"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  {{ estanc }}
                                </td>
                              </tr>
                              <!--Precios de Estancia-->
                              <tr>
                                <td>
                                  <b>Precios de Estancia</b>
                                </td>
                                <td><b>#</b></td>
                                <td
                                  v-for="(name, indF) in item.seasons.name"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  <b>{{ name }}</b>
                                </td>
                              </tr>
                              <tr
                                v-for="(estancia, indF) in item.estancias"
                                :key="Math.random() * (indF + 1)"
                              >
                                <td>{{ allRooms.filter(e => e.id === estancia.room.id)[0].name }}</td>
                                <td>{{ estancia.number }}</td>
                                <td
                                  v-for="(price, indG) in estancia.prices"
                                  :key="Math.random() * (indG + 1)"
                                >
                                  {{ price }}
                                </td>
                              </tr>
                              <!--Cupos-->
                              <tr>
                                <td>
                                  <b>Cupos</b>
                                </td>
                                <td><b>#</b></td>
                                <td
                                  v-for="(name, indF) in item.seasons.name"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  <b>{{ name }}</b>
                                </td>
                              </tr>
                              <tr
                                v-for="(cupo, indF) in item.cupos"
                                :key="Math.random() * (indF + 1)"
                              >
                                <td>{{ allRooms.filter(e => e.id === cupo.room.id)[0].name }}</td>
                                <td>{{ cupo.number }}</td>
                                <td
                                  v-for="(price, indG) in cupo.prices"
                                  :key="Math.random() * (indG + 1)"
                                >
                                  {{ price }}
                                </td>
                              </tr>
                              <!--Suplementos y Reducciones-->
                              <tr>
                                <td>
                                  <b>Suplementos y Reducciones</b>
                                </td>
                                <td><b>Tipo</b></td>
                                <td
                                  v-for="(name, indF) in item.seasons.name"
                                  :key="Math.random() * (indF + 1)"
                                >
                                  <b>{{ name }}</b>
                                </td>
                                <td><b>Notas</b></td>
                              </tr>
                              <tr
                                v-for="(suplem_rd, indF) in item.suplem_reduc"
                                :key="Math.random() * (indF + 1)"
                              >
                                <td>
                                  {{
                                    suplem_rd.suplemento.type === 'suplemento'
                                      ? allSuplementos.filter(e => e.id === suplem_rd.suplemento.id)[0].name
                                      : allReducciones.filter(e => e.id === suplem_rd.suplemento.id)[0].name
                                  }}
                                </td>
                                <td>{{ suplem_rd.tipo }}</td>
                                <td
                                  v-for="(price, indG) in suplem_rd.prices"
                                  :key="Math.random() * (indG + 1)"
                                >
                                  <fragment v-if="price.length <= 8">
                                    {{ price }}
                                  </fragment>
                                  <fragment v-else>
                                    <v-tooltip
                                      color="primary"
                                      top
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          icon
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiInformationOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ price }}</span>
                                    </v-tooltip>
                                  </fragment>
                                </td>
                                <td>
                                  <v-tooltip
                                    v-if="suplem_rd.notas"
                                    slot="append-outer"
                                    color="primary"
                                    top
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiInformationOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ suplem_rd.notas }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <!--Descripcion Suplementos-->
                              <tr>
                                <td><b>Descripción</b></td>
                                <td><b>Categoría</b></td>
                                <td><b>Tipo</b></td>
                                <td><b>Desde</b></td>
                                <td><b>Hasta</b></td>
                                <td><b>ADU</b></td>
                                <td><b>NIN</b></td>
                                <td><b>Cálculo por</b></td>
                                <td><b>Comidas</b></td>
                              </tr>
                              <tr
                                v-for="(suplem, indF) in item.caculate_supl"
                                :key="Math.random() * (indF + 1)"
                              >
                                <td>{{ suplem.description }}</td>
                                <td>{{ suplem.category }}</td>
                                <td>{{ suplem.type }}</td>
                                <td>{{ suplem.from }}</td>
                                <td>{{ suplem.to }}</td>
                                <td>{{ suplem.adu }}</td>
                                <td>{{ suplem.nin }}</td>
                                <td>{{ suplem.calcule }}</td>
                                <td>
                                  <v-tooltip
                                    v-if="suplem.foots"
                                    slot="append-outer"
                                    color="primary"
                                    top
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiInformationOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ suplem.foots }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <!--Rango-->
                              <tr>
                                <td><b>Rango</b></td>
                                <td><b>Años</b></td>
                                <td colspan="7"></td>
                              </tr>
                              <tr
                                v-for="(rang, indF) in item.rango"
                                :key="Math.random() * (indF + 1)"
                              >
                                <td>{{ rang.rango }}</td>
                                <td>{{ rang.operation }}</td>
                                <td
                                  colspan="7"
                                  class="text-left"
                                >
                                  {{ rang.years }}
                                </td>
                              </tr>
                              <!--Categoriade Habitación-->
                              <tr>
                                <td><b>Categoría de Habitación</b></td>
                                <td><b>#</b></td>
                                <td><b>Mínimo</b></td>
                                <td><b>Máximo</b></td>
                                <td colspan="5">
                                  Notas
                                </td>
                              </tr>
                              <tr
                                v-for="(category, indF) in item.category_room"
                                :key="Math.random() * (indF + 1)"
                              >
                                <td>{{ category.room.name }}</td>
                                <td>{{ category.number }}</td>
                                <td>{{ category.min }}</td>
                                <td>{{ category.max }}</td>
                                <td
                                  colspan="5"
                                  class="text-left"
                                >
                                  <v-tooltip
                                    v-if="category.nota"
                                    slot="append-outer"
                                    color="primary"
                                    top
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiInformationOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ category.nota }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </fragment>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <!--PARA LOS BLUE DIAMOND -->
                      <TableUploadLoteBlue
                        :name_proveedor="name_proveedor"
                        :list-hotel="listHotel"
                        :all-rooms="allRooms"
                        :all-planes="allPlanes"
                        :all-suplementos="allSuplementos"
                        :all-reducciones="allReducciones"
                        :item="item"
                        :index="index"
                      />
                    </fragment>
                  </v-col>
                </fragment>
              </fragment>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDeleteItem"
      scrollable
      max-width="380px"
    >
      <v-card
        v-if="model.name"
        max-height="400px"
      >
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.addDelete', { item: model.name.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDeleteItem = !isDialogVisibleDeleteItem"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingDelete"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR UPDATE ALL -->
    <v-dialog
      v-model="isDialogUpdate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.acceptChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.processUpload', { item: 'contratos de hoteles' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogUpdate = !isDialogUpdate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmUpdateAll()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR CANCEL UPLOAD -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.canceleUpload', { item: 'contratos de hoteles' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmCancele()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD CHANGE NOM -->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addChange', {
              item:
                type === 'hotel'
                  ? model.hotel.name
                  : type === 'room'
                    ? model.room.name
                    : type === 'planes'
                      ? model.planes.name
                      : type === 'suplementos'
                        ? model.suplementos.name
                        : type === 'reducciones'
                          ? model.reducciones.name
                          : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL REMPLACE-->
    <v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addRemplace', {
              remplace:
                type === 'hotel'
                  ? model.hotel.name
                  : type === 'room'
                    ? model.room.name
                    : type === 'planes'
                      ? model.planes.name
                      : type === 'suplementos'
                        ? model.suplementos.name
                        : type === 'reducciones'
                          ? model.reducciones.name
                          : '',
              item:
                type === 'hotel'
                  ? model.hotel.id
                    ? itemsHotel.filter(e => e.id === model.hotel.id).length > 0
                      ? itemsHotel.filter(e => e.id === model.hotel.id)[0].name
                      : ''
                    : ''
                  : type === 'room'
                    ? model.room.id
                      ? itemsRooms.filter(e => e.id === model.room.id).length > 0
                        ? itemsRooms.filter(e => e.id === model.room.id)[0].name
                        : ''
                      : ''
                    : type === 'planes'
                      ? model.planes.id
                        ? allPlanes.filter(e => e.id === model.planes.id).length > 0
                          ? allPlanes.filter(e => e.id === model.planes.id)[0].name
                          : ''
                        : ''
                      : type === 'suplementos'
                        ? model.suplementos.id
                          ? allSuplementos.filter(e => e.id === model.suplementos.id).length > 0
                            ? allSuplementos.filter(e => e.id === model.suplementos.id)[0].name
                            : ''
                          : ''
                        : type === 'reducciones'
                          ? model.reducciones.id
                            ? allReducciones.filter(e => e.id === model.reducciones.id).length > 0
                              ? allReducciones.filter(e => e.id === model.reducciones.id)[0].name
                              : ''
                            : ''
                          : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL ASOCIATE-->
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', {
              asociate:
                type === 'hotel'
                  ? model.hotel.name
                  : type === 'room'
                    ? model.room.name
                    : type === 'planes'
                      ? model.planes.name
                      : type === 'suplementos'
                        ? model.suplementos.name
                        : type === 'reducciones'
                          ? model.reducciones.name
                          : '',
              item:
                type === 'hotel'
                  ? model.hotel.id
                    ? itemsHotel.filter(e => e.id === model.hotel.id).length > 0
                      ? itemsHotel.filter(e => e.id === model.hotel.id)[0].name
                      : ''
                    : ''
                  : type === 'room'
                    ? model.room.id
                      ? itemsRooms.filter(e => e.id === model.room.id).length > 0
                        ? itemsRooms.filter(e => e.id === model.room.id)[0].name
                        : ''
                      : ''
                    : type === 'planes'
                      ? model.planes.id
                        ? allPlanes.filter(e => e.id === model.planes.id).length > 0
                          ? allPlanes.filter(e => e.id === model.planes.id)[0].name
                          : ''
                        : ''
                      : type === 'suplementos'
                        ? model.suplementos.id
                          ? allSuplementos.filter(e => e.id === model.suplementos.id).length > 0
                            ? allSuplementos.filter(e => e.id === model.suplementos.id)[0].name
                            : ''
                          : ''
                        : type === 'reducciones'
                          ? model.reducciones.id
                            ? allReducciones.filter(e => e.id === model.reducciones.id).length > 0
                              ? allReducciones.filter(e => e.id === model.reducciones.id)[0].name
                              : ''
                            : ''
                          : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE VALUE-->
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addDelete', {
              item:
                type === 'hotel'
                  ? model.hotel.name
                  : type === 'room'
                    ? model.room.name
                    : type === 'planes'
                      ? model.planes.name
                      : type === 'suplementos'
                        ? model.suplementos.name
                        : type === 'reducciones'
                          ? model.reducciones.name
                          : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CHANGE HOTEL -->
    <v-dialog
      v-model="isChangeHotel"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.hotel"
            class="mt-5"
          >
            <v-col
              cols="12"
              :md="model.hotel.operador_id ? '6' : '12'"
            >
              <v-text-field
                v-model="model.hotel.name"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col
              v-if="model.hotel.operador_id"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.hotel.id"
                :items="itemsHotel"
                :search-input.sync="searchHotel"
                hide-details
                hide-selected
                :label="$t('lbl.hotel')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.hotel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.hotel.operador_id"
                :items="itemsOperadores"
                :search-input.sync="searchOperador"
                hide-details
                hide-selected
                :label="$t('menu.operador')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
                @change="setOperadores"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.operador') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeHotel = !isChangeHotel"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.hotel"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    :disabled="!model.hotel.operador_id"
                    v-on="on"
                    @click="showAdd('hotel')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.hotel.id || !model.hotel.operador_id"
                    v-on="on"
                    @click="showRemplace('hotel', model.hotel)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.hotel.id || !model.hotel.operador_id"
                    v-on="on"
                    @click="showAsociate('hotel', model.hotel)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('hotel', model.hotel.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE ROOM -->
    <v-dialog
      v-model="isChangeRoom"
      max-width="750px"
    >
      <v-card height="350px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.room"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.room.name"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.room.id"
                :items="itemsRooms"
                :search-input.sync="searchRooms"
                hide-details
                hide-selected
                :label="$t('lbl.room')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.room') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="loadingHotels"
              cols="12"
              md="12"
            >
              <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
            </v-col>
            <v-col
              v-else
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="hotelsIds"
                :items="hotelsIds"
                hide-details
                hide-selected
                :label="$t('lbl.hotelsAsociado')"
                outlined
                dense
                disabled
                chips
                multiple
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeRoom = !isChangeRoom"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.room"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('room')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.room.id"
                    v-on="on"
                    @click="showRemplace('room', model.room)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.room.id"
                    v-on="on"
                    @click="showAsociate('room', model.room)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('room', model.room.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE PLANES -->
    <v-dialog
      v-model="isChangePlanes"
      max-width="750px"
    >
      <v-card height="250px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.planes"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.planes.name"
                :label="$t('menu.regimenAlimenticio')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.planes.id"
                :items="itemsPlanes"
                :search-input.sync="searchPlanes"
                hide-details
                hide-selected
                :label="$t('menu.regimenAlimenticio')"
                outlined
                dense
                item-text="siglas"
                item-value="id"
                clearable
                single-line
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.regimenAlimenticio') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.siglas"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.siglas }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangePlanes = !isChangePlanes"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.planes"
              cols="12"
              md="6"
              offset-md="2"
            >
              <!--<v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('planes')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.planes.id"
                    v-on="on"
                    @click="showRemplace('planes', model.tipo_destino)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>-->

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.planes.id"
                    v-on="on"
                    @click="showAsociate('planes', model.planes)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('planes', model.planes.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE SUPLEMENTOS -->
    <v-dialog
      v-model="isChangeSuplementos"
      max-width="750px"
    >
      <v-card
        v-if="model.suplementos"
        :height="model.suplementos.type === 'suplemento' ? '430px' : '330px'"
      >
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.suplementos"
            class="mt-2"
            no-gutters
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="model.suplementos.name"
                :label="$t('menu.suplementosReduc')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-radio-group
                v-model="model.suplementos.type"
                row
                hide-details
              >
                <v-radio
                  :label="$t('menu.suplementos')"
                  value="suplemento"
                ></v-radio>
                <v-radio
                  :label="$t('menu.reducciones')"
                  value="reduccion"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <fragment v-if="model.suplementos.type === 'suplemento'">
              <v-col
                cols="12"
                md="12"
                class="mt-4"
              >
                <v-autocomplete
                  v-model="model.suplementos.id"
                  :items="itemsSuplementos"
                  :search-input.sync="searchSuplementos"
                  hide-details
                  hide-selected
                  :label="$t('menu.suplementos')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  clearable
                  :disabled="!model.suplementos.type"
                  @click:clear="setSuplemento()"
                  @change="setSuplemento()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.suplementos') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-radio-group
                  v-model="model.suplementos.modality"
                  row
                  hide-details
                >
                  <v-radio
                    :label="$t('lbl.byNight')"
                    value="by_night"
                    class="col-3"
                  ></v-radio>
                  <v-radio
                    :label="$t('lbl.byRoom')"
                    value="by_room"
                    class="col-3"
                  ></v-radio>
                  <v-radio
                    :label="$t('lbl.byPax')"
                    value="by_pax"
                    class="col-3"
                  ></v-radio>
                  <v-radio
                    :label="$t('lbl.byRoomNight')"
                    value="by_room_night"
                    class="col-3"
                  ></v-radio>
                  <v-radio
                    :label="$t('lbl.byPaxNight')"
                    value="by_pax_night"
                    class="col-3"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </fragment>
            <v-col
              v-else-if="model.suplementos.type === 'reduccion'"
              cols="12"
              md="12"
              class="mt-4"
            >
              <v-autocomplete
                v-model="model.suplementos.id"
                :items="itemsReducciones"
                :search-input.sync="searchReducciones"
                hide-details
                hide-selected
                :label="$t('menu.reducciones')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.reducciones') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeSuplementos = !isChangeSuplementos"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.suplementos"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    :disabled="model.suplementos.type === 'suplemento' ? !model.suplementos.modality : false"
                    v-on="on"
                    @click="showAdd('suplementos')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.suplementos.id"
                    v-on="on"
                    @click="showRemplace('suplementos', model.suplementos)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.suplementos.id"
                    v-on="on"
                    @click="showAsociate('suplementos', model.suplementos)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('suplementos', model.suplementos.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CHANGE REDUCCIONES -->
    <v-dialog
      v-model="isChangeReducciones"
      max-width="750px"
    >
      <v-card height="290px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.reducciones"
            class="mt-2"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="model.reducciones.name"
                :label="$t('menu.reducciones')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="model.reducciones.id"
                :items="itemsReducciones"
                :search-input.sync="searchReducciones"
                hide-details
                hide-selected
                :label="$t('menu.reducciones')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.reducciones') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="ml-2">
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangeReducciones = !isChangeReducciones"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="model.reducciones"
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('reducciones')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.reducciones.id"
                    v-on="on"
                    @click="showRemplace('reducciones', model.reducciones)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.reducciones.id"
                    v-on="on"
                    @click="showAsociate('reducciones', model.reducciones)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('reducciones', model.reducciones.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiCancel,
  mdiInformationOutline,
  mdiListStatus,
  mdiFileReplaceOutline,
  mdiLinkVariantPlus,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

import TableUploadLoteBlue from '../utils/TableUploadLoteBlue.vue'

export default {
  components: {
    AppCardCode,
    Loading,
    TableUploadLoteBlue,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      modificando: false,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      name_proveedor: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiCancel,
        mdiInformationOutline,
        mdiListStatus,
        mdiFileReplaceOutline,
        mdiLinkVariantPlus,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {
        proveedor_id: null,
      },
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      edad_min: {},
      loading: false,
      loadingDelete: false,

      items: [],

      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteItem: false,
      isDialogUpdate: false,
      nameOlds: [],
      newNameOld: {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      itemsOperadores: [],
      allOperadores: [],
      searchOperador: null,

      itemsHotel: [],
      allHotel: [],
      listHotel: [],
      searchHotel: null,

      itemsRooms: [],
      allRooms: [],
      searchRooms: null,
      hotelsIds: [],
      loadingHotels: false,

      planesChange: [],
      itemsPlanes: [],
      allPlanes: [],
      searchPlanes: null,

      suplementosChange: [],
      itemsSuplementos: [],
      allSuplementos: [],
      searchSuplementos: null,

      reduccionesChange: [],
      itemsReducciones: [],
      allReducciones: [],
      searchReducciones: null,

      galery: [],
      editGeoTag: true,
      address: {},
      edades: [],
      numeros: [],
      file: null,
      processUpload: false,
      cancelarUpload: false,
      isAddChange: false,
      isAddRemplace: false,
      isAddAsociate: false,
      isAddDelete: false,
      isChangeHotel: false,
      isChangeRoom: false,
      isChangePlanes: false,
      isChangeSuplementos: false,
      isChangeReducciones: false,
      addChange: false,
      type: null,
      changing: false,
      lote: null,

      hotelsChange: [],
      rommsChange: [],
      categoriasChange: [],
      tiposDestinosChange: [],
      edadesChange: [],
      serviciosChange: [],
      interesesChange: [],
      logo: null,
      valueRemplace: null,
      typeRemplace: null,
      valueCsv: null,
      idRemplace: null,

      iconito: null,
      iconSelect: null,
      iconos: [],
      itemsIcons: [],
      searchIcons: null,
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    ...mapState({
      addressHotel: state => state.app.addressHotel,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },

    searchOperador(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOperador(val.toLowerCase())
      } else {
        this.itemsOperadores = []
      }
    },
    searchHotel(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      } else {
        this.itemsHotel = this.allHotel
      }
    },
    searchPlanes(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterPlanes(val.toLowerCase())
    },
    searchRooms(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterRooms(val.toLowerCase())
      } else {
        this.itemsRooms = []
      }
    },
    searchSuplementos(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterSuplementos(val.toLowerCase())
      } else {
        this.itemsSuplementos = []
      }
    },
    searchReducciones(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterReducciones(val.toLowerCase())
      } else {
        this.itemsReducciones = []
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getNomAll()

    // this.getLote()
  },
  methods: {
    ...mapMutations([
      'adicionarImgHotels',
      'updateImgHotels',
      'adicionarContactHotel',
      'updateContactHotel',
      'updateAddressHotel',
      'updateGeoTag',
      'setOnlyShow',
    ]),
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    filterOperador(v) {
      this.itemsOperadores = []
      if (v === '') {
        this.itemsOperadores = []
      } else {
        this.itemsOperadores = this.allOperadores.filter(e => e.name.toLowerCase())
      }
    },
    filterHotel() {
      this.itemsHotel = this.allHotel.filter(e => e.name.toLowerCase())
    },
    filterRooms(v) {
      this.itemsRooms = []
      if (v === '') {
        this.itemsRooms = []
      } else {
        this.itemsRooms = this.allRooms.filter(e => e.name.toLowerCase())
      }
    },
    filterPlanes() {
      this.itemsPlanes = this.allPlanes.filter(e => e.siglas.toLowerCase())
    },
    filterSuplementos(v) {
      this.itemsSuplementos = []
      if (v === '') {
        this.itemsSuplementos = []
      } else {
        this.itemsSuplementos = this.allSuplementos.filter(e => e.name.toLowerCase())
      }
    },
    filterReducciones(v) {
      this.itemsReducciones = []
      if (v === '') {
        this.itemsReducciones = []
      } else {
        this.itemsReducciones = this.allReducciones.filter(e => e.name.toLowerCase())
      }
    },

    getNomAll() {
      this.getProveedores()
      this.getOperadores()
      this.getHotel()
      this.getRooms()
      this.getPlanes()
      this.getSuplementos()
      this.getReducciones()
    },
    getProveedores() {
      this.axios
        .get('afiliados/byproduct/hotels', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allOperadores = res.data.data
        })
    },
    setOperadores() {
      this.allHotel = []
      if (this.model.hotel.operador_id) {
        this.allHotel = this.listHotel.filter(e => e.operador_id === this.model.hotel.operador_id)
        this.itemsHotel = []

        /* if (this.allOperadores.filter(e => e.id === this.model.hotel.operador_id)[0].marcas.length > 0) {
          this.allHotel = this.allOperadores.filter(e => e.id === this.model.hotel.operador_id)[0].marcas
          this.itemsHotel = this.allHotel
        } */
      }
    },
    getHotel() {
      this.axios
        .post(
          'hotels/list',
          { per_page: 1000 },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          this.allHotel = res.data.data
          this.listHotel = res.data.data
        })
        .finally(() => this.getLote())
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allRooms = res.data.data
        })
    },
    getPlanes() {
      this.axios
        .get(`nom_regimen_alimenticio?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allPlanes = res.data.data
          this.itemsPlanes = res.data.data
        })
    },
    getSuplementos() {
      this.axios
        .get(`nom_suplemento_contrate/hotel?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allSuplementos = res.data.data
        })
    },
    setSuplemento() {
      if (this.model.suplementos) {
        if (this.model.suplementos.id) {
          if (this.allSuplementos.filter(e => e.id === this.model.suplementos.id).length > 0) {
            this.model.suplementos.modality = this.allSuplementos.filter(
              e => e.id === this.model.suplementos.id,
            )[0].modality
          }
        } else {
          this.model.suplementos.modality = null
        }
      } else {
        this.model.suplementos.modality = null
      }
    },
    getReducciones() {
      this.axios
        .get(`nom_reducciones_contrate/hotel?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allReducciones = res.data.data
        })
    },

    getLote() {
      this.axios
        .get('lotes-contrate-cars', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          // console.log(res)

          this.lote = res.data.data.data
          if (this.lote) {
            this.hotelsChange = res.data.data.data.hoteles
            this.rommsChange = res.data.data.data.habitaciones
            this.planesChange = res.data.data.data.planes
            this.suplementosChange = res.data.data.data.suplementos
            this.reduccionesChange = res.data.data.data.reducciones
            this.name_proveedor = res.data.data.data.name_proveedor
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    acceptUpload() {
      this.isDialogUpdate = true
    },
    canceleUpload() {
      this.isDialogVisibleDelete = true
    },
    confirmUpdateAll() {
      this.modificando = true

      this.axios
        .post(
          'lotes-contrate-cars/process-all',
          { id: this.lote.id },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    confirmCancele() {
      this.cancelarUpload = true
      this.axios
        .delete(`lotes/${this.lote.id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    modalHotel(item) {
      this.type = 'hotel'
      this.model = {
        hotel: {
          name: item,
          operador_id: null,
          valueCsv: item,
        },
      }
      this.isChangeHotel = true
    },
    modalRoom(item) {
      this.model = {
        room: {
          name: item,
          valueCsv: item,
        },
      }
      this.loadingHotels = true
      this.isChangeRoom = true
      this.getHotelsIdsLote()
    },
    getHotelsIdsLote() {
      if (this.model.room) {
        this.axios
          .post(
            `lotes-contrate-cars/get-hotels-asociate/${this.lote.id}`,
            { room_name: this.model.room.valueCsv },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.hotelsIds = res.data.data.hotels
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loadingHotels = false))
      }
    },
    modalPlanes(item) {
      this.model = {
        planes: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangePlanes = true
    },
    modalSuplementos(item) {
      this.model = {
        suplementos: {
          name: item.name,
          valueCsv: item.name,
          type: item.type,
          modality: null,
        },
      }
      this.isChangeSuplementos = true
    },
    modalReducciones(item) {
      this.model = {
        reducciones: {
          name: item,
          valueCsv: item,
        },
      }
      this.isChangeReducciones = true
    },

    showAdd(type) {
      this.type = type
      this.isAddChange = true
    },
    showRemplace(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      if (type === 'suplementos') {
        this.typeRemplace = newValue.type
      } else {
        this.typeRemplace = null
      }
      this.valueCsv = newValue.valueCsv
      this.idRemplace = newValue.id
      this.isAddRemplace = true
    },
    showAsociate(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      if (type === 'suplementos') {
        this.typeRemplace = newValue.type
      } else {
        this.typeRemplace = null
      }
      this.valueCsv = newValue.valueCsv
      this.idRemplace = newValue.id
      this.isAddAsociate = true
    },
    showDelete(type, newValue) {
      this.type = type
      this.valueRemplace = newValue

      if (type === 'suplementos') {
        this.typeRemplace = this.model.suplementos.type
      } else {
        this.typeRemplace = null
      }
      this.isAddDelete = true
    },

    confirmChange(type) {
      this.axios
        .put(
          `lotes/update/${this.lote.id}`,
          { type, changes: this.model },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddChange() {
      this.addChange = true
      this.axios
        .post(
          `lotes-contrate-cars/add-change/${this.lote.id}`,
          { type: this.type, changes: this.model },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        typeName: this.typeRemplace,
        valueCsv: this.valueCsv,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.servicios) {
        if (this.model.servicios.icon) {
          json.icon = this.model.servicios.icon
        }
      }

      this.axios
        .post(`lotes-contrate-cars/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        typeName: this.typeRemplace,
        valueCsv: this.valueCsv,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.servicios) {
        if (this.model.servicios.icon) {
          json.icon = this.model.servicios.icon
        }
      }

      this.axios
        .post(`lotes-contrate-cars/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddDelete() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        typeName: this.typeRemplace,
        type: this.type,
      }

      this.axios
        .post(`lotes-contrate-cars/add-delete/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    deleteItem(item) {
      this.isDialogVisibleDeleteItem = true
      this.model = {
        name: {
          id: item.id,
          name: item.name.name,
        },
      }
    },
    confirmDelete() {
      this.loadingDelete = true
      const json = {
        id: this.model.name.id,
      }

      this.axios
        .post(`lotes-contrate-cars/delete-hotel/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .finally(() => this.resetFile())
    },

    changeProveedor() {
      if (this.model.proveedor_id) {
        const mod = this.proveedores.filter(a => a.id === this.model.proveedor_id)[0]
        this.name_proveedor = mod.slug

        // this.name_proveedor = mod.name_comercial

        this.model.identificador = `${mod.name_comercial}`
      } else {
        this.model.identificador = null
        this.name_proveedor = null
      }
    },

    setFile() {
      if (this.file) {
        this.modificando = true
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append(
          'data',
          JSON.stringify({
            proveedor_id: this.model.proveedor_id,
            identificador: this.model.identificador,
          }),
        )
        this.axios
          .post('contrate_hotels/upload-lote', formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            // console.log(response)
            if (response.data.success === false) {
              this.$toast.error(
                this.$t('msg.errorHotel', { item: response.data.hotel, element: response.data.element }),
              )
            } else {
              this.$toast.success(this.$t('msg.verifiqueInfo'))
            }

            this.getLote()
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      }
    },
    resetFile() {
      this.file = null
      this.model = {}
      this.logo = null
      this.type = null
      this.iconito = null
      this.iconSelect = null
      this.modificando = false
      this.isDialogVisibleDeleteItem = false
      this.isDialogUpdate = false
      this.loadingDelete = false
      this.cancelarUpload = false
      this.isDialogVisibleDelete = false
      this.isChangeHotel = false
      this.isChangeRoom = false
      this.isChangePlanes = false
      this.isChangeSuplementos = false
      this.isChangeReducciones = false
      this.isAddChange = false
      this.isAddRemplace = false
      this.addChange = false
      this.valueRemplace = null
      this.typeRemplace = null
      this.valueCsv = null
      this.idRemplace = null
      this.isAddAsociate = false
      this.isAddDelete = false

      this.searchOperador = null
      this.searchMarcas = null
      this.searchTipoDestino = null
      this.searchServicios = null
      this.searchIcons = null
      this.searchIntereses = null
      this.name_proveedor = null
      this.hotelsIds = []
    },
  },
}
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.8rem !important;
  height: 2rem !important;
}
</style>
